body {
    margin: 0;
    font-family: 'Roboto', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
}
